<template>
  <MyIndexPage></MyIndexPage>
</template>

<script>

import MyIndexPage from './views/MyIndexPage';


export default {
  name: 'App',
  metaInfo: {
    title: '广西诺拉信息科技有限公司',
    meta: [
      { name: 'description', content: '广西诺拉信息科技有限公司，一诺医途' },
      { name: 'keywords', content: '广西诺拉信息科技有限公司，一诺医途，医讯' }
    ]
  },
  components: {
    MyIndexPage
  }
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
  color: #333333;
}
</style>
