<template>
    <div class="top">
        <div class="mian">
            广西诺拉信息科技有限公司
        </div>
    </div>

    <div class="banner">
        <div class="top">
        </div>
        <div class="center">
            <div class="box1">
                <img src="../assets/ios.png" alt="" class="img1">
                <img src="../assets/ioscode.png" alt="" class="code1">
            </div>
            <div class="box2">
                <img src="../assets/az.png" alt="" class="img2">
                <img src="../assets/azcode.png" alt="" class="code2">
            </div>
        </div>
    </div>

    <div class="po">
        <div class="top">
        </div>
        <div class="center">
            <div class="box1 box">
                <div class="p">
                    微学术专区
                </div>
                <div class="content">
                    微专业是由诺拉信息联合领域资深专业人士，以学术普及为导向，完善传统专业学术教面临时间空间限制的问题，让用户快速、全面地掌握相关技能，并进行测验。按要求完成学习，测验通过可获得专业认定证书，还可以获取相关机构的进一步学术案例研究机会。
                </div>
            </div>
            <div class="box2 box">
                <div class="p">
                    系列课
                </div>
                <div class="content">
                    将某一领域的内容进行打包并有序地呈现，给用户提供完整的、有体系的学习方案。解决用户有计划学习某项技能，但是又不知道应该如何开始学起的问题，同时能够让学员快速、全面地学握相关知识点。
                </div>

            </div>
            <div class="box3 box">
                <div class="p">
                    案例库
                </div>
                <div class="content">
                    题库是诺拉信息为各类热门考试的用户研发的案例类学习模块。目前各级用户可不同领域的真实案例研究学习，并参与案例的分析讨论。同时，案例库会和相应考试类课程、学习计划有机整合，完整辅助用户整个学习过程。
                </div>

            </div>

        </div>
    </div>

    <div class="cs">
        <div class="csTitle">

        </div>
        <div class="center">
            <div class="left">

            </div>
            <div class="right">
                <div class="rt">

                </div>
                <div>
                    针对企业需求 提供学习解决方案 共同打造学习型云专区
                </div>

            </div>
        </div>
    </div>

    <div class="aboutUs">
        <div class="aUt">

        </div>

        <div class="aUcenter">
            <div class="p">
                广西诺拉信息科技有限公司成立于2021年01月07日，诞生在国家“互联网+”政策背景下，是一家致力于开发互联网移动产品的科技企业，为企业提供新型的学术营销解决方案。
            </div>
            <div class="p">
                目前业务线是为各企业提供学术推广的一诺医途APP，APP专注于为广大的用户群体提供学术成长支撑，为企业提供合规学术推广解决方案。
            </div>
            <div class="p">
                广西诺拉信息科技有限公司率先开启了改变传统行业的学术推广模式，专注于互联网行业提供新型的学术营销解决方案，推动行业快速健康发展。
            </div>

        </div>

    </div>

    <div class="footer">
        <div class="callUs">
            联系我们
        </div>
        <div class="p">
            公司邮箱：<span>gxnuola23@163.com</span>
        </div>
        <div class="p">
            联系电话：<span>19934517536</span>
        </div>
        <div class="p">
            公司地址：<span>中国(广西)自由贸易试验区南宁片区金龙路2号南宁万科大厦C座2层201号房屋D7-11号</span>
        </div>
    </div>

    <div class="beian">
        Copyright © 2023. All Rights Reserved <a href="https://beian.miit.gov.cn/">桂ICP备2024017919号-1</a>
    </div>
</template>

<script setup>

</script>

<style lang="less" scoped>
.top {
    width: 100%;
    height: 96px;
    box-sizing: border-box;

    .mian {
        width: 1200px;
        line-height: 96px;
        margin: 0 auto;
        font-weight: bold;
        font-size: 16px;
    }
}

.banner {
    width: 100%;
    height: 864px;
    background-image: url('../assets/bg1.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-sizing: border-box;
    border-top: 1px solid transparent;

    display: flex;
    flex-direction: column;
    align-items: center;

    .top {
        width: 536px;
        height: 206px;
        background-image: url('../assets/bg2.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-top: 182px;
        margin-bottom: 14px;
    }

    .center {
        display: flex;

        .box1 {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 113px;

            .img1 {
                margin-bottom: 14px;
                cursor: pointer
            }

            .img1:hover~.code1 {
                display: block;
            }

            .code1 {
                display: none;
            }
        }

        .box2 {
            display: flex;
            flex-direction: column;
            align-items: center;

            .img2 {
                margin-bottom: 14px;
                cursor: pointer
            }

            .img2:hover~.code2 {
                display: block;
            }

            .code2 {
                display: none;
            }

        }

    }

}

.po {
    width: 100%;
    height: 730px;
    background-color: #F3F4F8;
    border-top: 1px solid #F3F4F8;
    display: flex;
    flex-direction: column;
    align-items: center;

    .top {
        width: 120px;
        height: 71px;
        background-image: url('../assets/poTitle.png');
        background-size: 100% 100%;
        margin-top: 113px;
        margin-bottom: 40px;
    }

    .center {
        display: flex;

        .box1 {
            background-image: url('../assets/po1.png');
        }

        .box2 {
            background-image: url('../assets/po2.png');
            margin: 0 118px;
        }

        .box3 {
            background-image: url('../assets/po3.png');
        }

        .box {
            width: 315px;
            height: 330px;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            box-sizing: border-box;
            padding: 38px 45px 0 45px;

            .p {
                font-weight: bold;
                margin-bottom: 10px;
            }

            .content {
                color: #999999;
            }
        }

    }
}

.cs {
    width: 100%;
    height: 730px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid transparent;

    .csTitle {
        width: 125px;
        height: 71px;
        background-image: url('../assets/cs1.png');
        margin-top: 68px;
        margin-bottom: 64px;
    }

    .center {
        width: 1180px;
        // border-top: 1px solid transparent;
        display: flex;
        align-items: center;

        .left {
            width: 703px;
            height: 426px;
            background-image: url('../assets/cscs.png');
            background-size: 100% 100%;
            margin-right: 117px;
        }

        .right {
            width: 282px;
            font-weight: bold;

            .rt {
                width: 282px;
                height: 33.17px;
                background-image: url('../assets/cszs.png');
                margin-bottom: 27px;
                font-weight: bold;
            }
        }

    }


}

.aboutUs {
    width: 100%;
    height: 730px;
    background-image: url('../assets/asb.png');
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 113px;

    .aUt {
        width: 120px;
        height: 71px;
        background-image: url('../assets/ast.png');
        margin-bottom: 25px;
    }

    .aUcenter {
        width: 1182px;
        height: 369px;
        background: #fff;
        border-radius: 16px;
        box-sizing: border-box;
        padding: 67px 116px 0 116px;

        .p {
            margin-bottom: 20px;
            line-height: 25px;
            // display: flex ;
            // align-items: center;
            position: relative;
        }

        .p::before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background-color: #B7F7DD;
            border-radius: 50%;
            position: absolute;
            top: 18px;
            left: -24px;
        }
    }

}

.footer {
    width: 100%;
    height: 640px;
    background-color: #212631;
    display: flex;
    flex-direction: column;
    align-items: center;

    .callUs {
        color: #fff;
        font-size: 30px;
        font-weight: bold;
        margin: 64px 0 110px 0;
    }

    .p {
        width: 1180px;
        color: #fff;
        margin-bottom: 50px;
        font-size: 26px;

        span {
            color: #999;
            font-size: 20px;
        }
    }
}

.beian {
    background-color: #212631;
    height: 90px;
    color: #fff;
    text-align: center;
    line-height: 90px;

    a {
        color: #fff;
    }
}</style>